
import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ResetSenhaService } from './reset-senha.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
    selector: 'app-reset-senha',
    templateUrl: './reset-senha.component.html',
    styleUrls: ['./reset-senha.component.css'],
    providers: [MessageService, ConfirmationService],
  })

  export class ResetSenhaComponent {
    username: string = '';
    message: string = '';
    lifeMessageToast: number = 7000;

    constructor(
        private messageService: MessageService,
        private service: ResetSenhaService
        ) {}

    onSubmit() {
      this.service.requisisarNovaSenha(this.username)
      .toPromise()
      .then((response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Reset',
          detail: `Se o nome de usuário "${this.username}" estiver cadastrado, um email esta sendo enviado com a nova senha.`,
          life: this.lifeMessageToast,
        });
      })
      .catch((error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Não foi possível se comunicar com a central.',
          detail: error.error,
          life: this.lifeMessageToast,
        });
      });

    }
      
  }
