<app-menu-bar></app-menu-bar>
<div class="projetos-container">
  <div style="text-align: center">Manutenção de Projetos</div>
  <p-toolbar>
    <ng-template pTemplate="right">
      <div class="card flex justify-content-center">
        <p-toast></p-toast>
      </div>
      <button
        pButton
        pRipple
        icon="pi pi-plus"
        label="Adicionar"
        class="p-button-info mr-2"
        (click)="add()"
      ></button>
    </ng-template>
    <ng-template pTemplate="left">
      Data: &nbsp;&nbsp;
      <p-dropdown
        [(ngModel)] ="tipoFiltro"
        [options]="tipoDatas"
        optionLabel="name"
        optionValue="value"
        [showClear]="true"
        placeholder="Selecione a data a ser filtrada" /> &nbsp;&nbsp;
      <p-calendar
        selectionMode="range"
        dateFormat="dd/mm/yy"
        [(ngModel)]="filtroData"
        [showButtonBar]="true"
        [readonlyInput]="true"/>&nbsp;&nbsp;
      <p-button
        label="Pesquisar"
        (onClick)="fetchContent()"
      />
    </ng-template>
  </p-toolbar>

  <div class="table-container">
    <p-table
      styleClass="p-datatable-gridlines"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
      [value]="projetos"
      [paginator]="true"
      [rows]="15"
      [rowsPerPageOptions]="[10, 15, 20]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{first} até {last} (Total de Registros: {totalRecords})"
      [globalFilterFields]="[
        'dados.grupo',
        'dados.cliente',
        'dados.solucao',
        'dados.escopo',
        'dados.origem',
        'dados.ativacao',
        'dados.dtcontrato',
        'dados.dtkickoff',
        'dados.dttesteunit',
        'dados.dttestinteg',
        'dados.dtgolive',
        'dados.status'
      ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="grupo.nome">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Grupo
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="grupo.nome"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="grupo.nome"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                ></p-columnFilter>
              </div>
            </div>
          </th>
          <th pSortableColumn="cliente">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Cliente
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="cliente"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="cliente"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="solucao">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Solução
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="solucao"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="solucao"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="escopo">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Escopo
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="escopo"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="escopo"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="origem">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                ERP
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="origem"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="origem"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="ativacao">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Ativação
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="ativacao"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="ativacao"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="dtcontrato">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Contrato
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="dtcontrato"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="dtcontrato"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                  ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="dtkickoff">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Kickoff
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="dtkickoff"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="dtkickoff"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                  ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="dttestunit">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Unitário
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="dttestunit"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="dttestunit"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                  ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="dttestinteg">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Integrado
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="dttestinteg"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="dttestinteg"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                  ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="dtgolive">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Go-Live
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="dtgolive"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="dtgolive"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                  ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="statusDescricao">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Status
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="statusDescricao"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="statusDescricao"
                  display="input"
                  matchMode="contains"
                  [showMatchModes]="true"
                  [showOperator]="true"
                  [showAddButton]="true"
                  ></p-columnFilter>
              </div>
            </div>
          </th>

          <th style="text-align: center">Operação</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-dados>
        <tr>
          <td style="text-align: center">{{ dados.grupo.nome }}</td>
          <td style="text-align: center">{{ dados.cliente }}</td>
          <td style="text-align: center">{{ dados.solucao }}</td>
          <td style="text-align: center">{{ dados.escopo }}</td>
          <td style="text-align: center">{{ dados.origem }}</td>
          <td style="text-align: center">{{ dados.ativacao }}</td>
          <td style="text-align: center">{{ dados.dtcontrato | date : "yyyy-MM-dd" }}</td>
          <td style="text-align: center">{{ dados.dtkickoff | date : "yyyy-MM-dd" }}</td>
          <td style="text-align: center">{{ dados.dttestunit | date : "yyyy-MM-dd" }}</td>
          <td style="text-align: center">{{ dados.dttestinteg | date : "yyyy-MM-dd" }}</td>
          <td style="text-align: center">{{ dados.dtgolive | date : "yyyy-MM-dd" }}</td>
          <td style="text-align: center">{{ dados.statusDescricao }}&nbsp;
            <i *ngIf="dados.statusDescricao == 'Encerrado' && dados.validaEncerramentoDescricao == 'Pendente'" class="pi pi-question-circle" style="color:orange" pTooltip="Pendente validação pelo Suporte" (click)="validaEncerramento(dados)"></i>
            <i *ngIf="dados.statusDescricao == 'Encerrado' && dados.validaEncerramentoDescricao == 'Validado'" class="pi pi-check" style="color:limegreen" pTooltip="Validado pelo Suporte"></i>
            &nbsp;
            <i *ngIf="dados.pesquisaRespondida == false" class="pi pi-server" style="color:orange" pTooltip="Confirmar Pesquisa de Satisfaçao" (click)="modificaPesquisaSatisfacao(dados)"></i>
            <i *ngIf="dados.pesquisaRespondida == true" class="pi pi-server" style="color:limegreen" pTooltip="Cancelar Pesquisa de Satisfaçao" (click)="modificaPesquisaSatisfacao(dados)"></i>
          </td>
          <td style="text-align: center">
            <i class="pi pi-file-edit" style="color:limegreen" pTooltip="Editar" tooltipPosition="left" (click)="addEdit(dados)">&nbsp;</i>
            <i class="pi pi-comment" style="color: gray;" pTooltip="Comentário" tooltipPosition="left" (click)="openComentario(dados)">&nbsp;</i>
            <i class="pi pi-paperclip"  style="color: gray;"  pTooltip="Anexar" tooltipPosition="left" (click)="openUpload(dados)">&nbsp;</i>
            <i class="pi pi-trash"      style="color: red;"   pTooltip="Apagar" tooltipPosition="left" (click)="deletarProjeto(dados)">&nbsp;</i>
            <i class="pi pi-info-circle" style="color: blue;" pTooltip="Histórico" tooltipPosition="left" (click)="openHistorico(dados)">&nbsp;&nbsp;</i>
            <i *ngIf="dados.qtdOcorrenciasPendentes > 0" class="pi pi-exclamation-triangle" style="color: orange;"
              pTooltip="Ocorrências" tooltipPosition="left" (click)="openOcorrencia(dados)">{{ dados.qtdOcorrenciasPendentes }}</i><BR>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <p-confirmDialog
      appendTo="body"
      icon="pi pi-exclamation-triangle"
      [position]="'top'"
      [baseZIndex]="10000"
    ></p-confirmDialog>
    <p-dialog
      header="Cadastro de projetos"
      [(visible)]="visible"
      [style]="{ width: '750px' }"
      [modal]="true"
      (onHide)="hideDialog()"
      styleClass="p-fluid"
    >
      <ng-template pTemplate="header">
        <h2 *ngIf="projeto.id">Editar Projeto</h2>
        <h2 *ngIf="!projeto.id">Novo Projeto</h2>
      </ng-template>

      <ng-template pTemplate="content">
        <div class="grid">
          <div class="p-field col-6 p-fluid">
            <label>Grupo: *</label>
            <p-dropdown
              [style]="{ width: '100%', overflow: 'visible !important' }"
              placeholder="Selecione um grupo"
              [options]="grupos"
              optionLabel="nome"
              id="grupo"
              (onChange)="grupoSelecionado($event)"
              [(ngModel)]="projeto.grupo"
              [required]="true"
              pTooltip="Campo Obrigatório"
            >
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !projeto.grupo">Grupo é obrigatório.</small>
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="cliente">Cliente: *</label>
            <input
              type="text"
              pInputText
              id="cliente"
              [(ngModel)]="projeto.cliente"
              [required]="true"
              pTooltip="Campo Obrigatório"
            />
            <small class="p-error" *ngIf="submittedCliente && !projeto.cliente" >Cliente é obrigatório.</small>
          </div>

          <div class="p-field col-6 p-fluid">
            <label>Solução: *</label>
            <p-dropdown
              [style]="{ width: '100%', overflow: 'visible !important' }"
              placeholder="Selecione uma solução"
              [options]="solucoes"
              (onChange)="solucaoSelecionado($event)"
              [(ngModel)]="projeto.solucao"
              [required]="true"

              pTooltip="Campo Obrigatório"
            >
            </p-dropdown>
            <small class="p-error" *ngIf="submittedSolucao && !projeto.solucao">Solução é obrigatória.</small>
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="escopo">Escopo: </label>
            <input
              type="text"
              pInputText
              id="escopo"
              [(ngModel)]="projeto.escopo"
            />
          </div>


          <div class="p-field col-6 p-fluid">
            <label>ERP: </label>
            <p-dropdown
              [style]="{ width: '100%', overflow: 'visible !important' }"
              placeholder="Selecione uma solução"
              [options]="origens"
              (onChange)="origemSelecionado($event)"
              [(ngModel)]="projeto.origem"
              [required]="true"

              pTooltip="Campo Obrigatório"
            >
            </p-dropdown>
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="ativacao">Ativação: </label>
            <input
              type="text"
              pInputText
              id="ativacao"
              [(ngModel)]="projeto.ativacao"
            />
          </div>

          <div class="p-field col-6 p-fluid">
            <label>Status: *</label>
            <p-dropdown
              [options]="status"
              optionValue="valor"
              optionLabel="chave"
              [(ngModel)]="projeto.status"
              pTooltip="Campo Obrigatório"
            >
            </p-dropdown>
            <small class="p-error"*ngIf="submittedStatus && !projeto.status">Status é obrigatório.</small>
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="cadastro">Data Cadastro: </label>
            <input
              type="date"
              pInputText
              id="cadastro"
              [(ngModel)]="projeto.dtcadastro"
              disabled
            />
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="contrato">Data Contrato: </label>
            <input
              type="date"
              pInputText
              id="contrato"
              [(ngModel)]="projeto.dtcontrato"
            />
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="kickoff">Data Kickoff: </label>
            <input
              type="date"
              pInputText
              id="kickoff"
              [(ngModel)]="projeto.dtkickoff"
            />
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="unitario">Data Teste Unitário: </label>
            <input
              type="date"
              pInputText
              id="unitario"
              [(ngModel)]="projeto.dttestunit"
            />
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="integrado">Data Teste Integrado: </label>
            <input
              type="date"
              pInputText
              id="integrado"
              [(ngModel)]="projeto.dttestinteg"
            />
          </div>

          <div class="p-field col-6 p-fluid">
            <label for="golive">Data GoLive: </label>
            <input
              type="date"
              pInputText
              id="golive"
              [(ngModel)]="projeto.dtgolive"
            />
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="footer">
        <button
          pButton
          label="{{ projeto.id ? 'Atualizar' : 'Salvar' }}"
          icon="pi pi-check"
          class="b-salvar"
          (click)="salvarProjeto()"
        ></button>
        <p-button
          pRipple
          label="Cancelar"
          icon="pi pi-times"
          class="b-cancelar"
          styleClass="p-button-secondary"
          (click)="hideDialog()"
        ></p-button>
      </ng-template>
    </p-dialog>

    <!-- Dialog de inserir comentario -->
    <p-dialog
      header="Inserir Comentário"
      [(visible)]="comentarioDialog"
      [style]="{ width: '1000px', height:'500px' }"
      [modal]="true"
      (onHide)="hideComentario()"
      styleClass="p-fluid"
    >
      <ng-template pTemplate="content">
        <div class="content-container">
          <div class="textarea-container">
            <textarea
              rows="5"
              cols="110"
              [(ngModel)]="comentario.detalhe"
              class="custom-textarea">
            </textarea>
            <small class="p-error" *ngIf="submitted && !comentario.detalhe">Preencher campo!
            </small>
          </div>
        <div class="table-container">
          <p-table [value]="comentarios" [globalFilterFields]="['detalhe']" >
            <ng-template pTemplate="header">
              <tr>

                <th pSortableColumn="data">
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex justify-content-between align-items-center">
                      Data
                    </div>
                    <div class="flex justify-content-between align-items-center">
                      <p-sortIcon field="data"></p-sortIcon>
                      <p-columnFilter
                        type="text"
                        field="data"
                        display="input"
                        matchMode="contains"
                        [showMatchModes]="true"
                        [showOperator]="true"
                        [showAddButton]="true"
                      ></p-columnFilter>
                    </div>
                  </div>
                </th>

                <th pSortableColumn="detalhe">
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex justify-content-between align-items-center">
                      Detalhe
                    </div>
                    <div class="flex justify-content-between align-items-center">
                      <p-sortIcon field="detalhe"></p-sortIcon>
                      <p-columnFilter
                        type="text"
                        field="detalhe"
                        display="input"
                        matchMode="contains"
                        [showMatchModes]="true"
                        [showOperator]="true"
                        [showAddButton]="true"
                      ></p-columnFilter>
                    </div>
                  </div>
                </th>

                <th pSortableColumn="usuario">
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex justify-content-between align-items-center">
                      Usuário
                    </div>
                    <div class="flex justify-content-between align-items-center">
                      <p-sortIcon field="usuario"></p-sortIcon>
                      <p-columnFilter
                        type="text"
                        field="usuario"
                        display="input"
                        matchMode="contains"
                        [showMatchModes]="true"
                        [showOperator]="true"
                        [showAddButton]="true"
                      ></p-columnFilter>
                    </div>
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-comentario>
              <tr>
                <td>{{ comentario.data | date : "yyyy-MM-dd hh:mm" }}</td>
                <td>{{ comentario.detalhe }}</td>
                <td>{{ comentario.usuario }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </ng-template>

      <ng-template pTemplate="footer">
        <button
          pButton
          label="Salvar"
          icon="pi pi-check"
          class="b-salvar"
          (click)="inserirComentario()"
        ></button>
        <p-button
          pRipple
          label="Cancelar"
          icon="pi pi-times"
          class="b-cancelar"
          styleClass="p-button-secondary"
          (click)="hideComentario()"
        ></p-button>
      </ng-template>
    </p-dialog>

    <!-- Dialog de inserir anexo -->
    <p-dialog
      header="Anexar"
      [(visible)]="uploadDialog"
      [modal]="true"
      styleClass="p-fluid"
    >
      <ng-template pTemplate="content">
        <div>
          <p-toast></p-toast>

          <div class="upload-container">
            <p-fileUpload
              name="file[]"
              [customUpload]="true"
              (uploadHandler)="uploadFile($event)"
              (onUpload)="uploadFile($event)"
              [multiple]="true"
              accept
              [maxFileSize]="10000000"
              chooseLabel="Selecionar Arquivo"
              cancelLabel="Cancelar"
              uploadLabel="Enviar"
              uploadIcon="pi pi-cloud-upload"
            >
              <ng-template pTemplate="content">
                <ul *ngIf="uploadFiles.length">
                  <li *ngFor="let file of uploadFiles">
                    {{ file.name }} - {{ file.size }} bytes
                  </li>
                </ul>
              </ng-template>
            </p-fileUpload>
          </div>

          <div class="table-container">
            <p-table
              [value]="uploads"
              [globalFilterFields]="['nome']"
              class="custom-table"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>Nome</th>
                  <th>Download</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-upload>
                <tr>
                  <td>{{ upload.nome }}</td>
                  <td>
                    <div>
                      <a #downloadLink></a>
                      <p-button
                        icon="pi pi-cloud-download"
                        style0Class="p-button-rounded p-button-secondary p-button-text"
                        (click)="downloadArquivo(upload)"
                      >
                      </p-button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </ng-template>
    </p-dialog>
  </div>

  <!-- Dialog de historico de alterações -->
  <p-dialog
      header="Histórico do Projeto"
      [(visible)]="dialogHistorico"
      [modal]="true"
      styleClass="p-fluid"
      (onHide)="hideHistorico()">
      <ng-template pTemplate="content">
        <div class="table-historico">
          <p-table #tb2 [value]="projetosAud" styleClass="p-datatable-gridlines"
            [tableStyle]="{ 'min-width': '50rem' }"
            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
            [globalFilterFields]="['projetoAud.grupo','projetoAud.usuario','projetoAud.data','projetoAud.operacao','projetoAud.detalhe']">
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="grupo">
                  <div class="flex justify-content-between align-items-center">
                    Grupo
                    <div class="flex justify-content-between align-items-center">
                      <p-sortIcon field="grupo"></p-sortIcon>
                      <p-columnFilter field="grupo" display="menu" matchMode="contains"></p-columnFilter>
                    </div>
                  </div>
                </th>
                <th pSortableColumn="usuario">
                  <div class="flex justify-content-between align-items-center">
                    Usuário
                    <div class="flex justify-content-between align-items-center">
                      <p-sortIcon field="usuario"></p-sortIcon>
                      <p-columnFilter field="usuario" display="menu" matchMode="contains"></p-columnFilter>
                    </div>
                  </div>
                </th>
                <th pSortableColumn="data">
                  <div class="flex justify-content-between align-items-center">
                    Data
                    <div class="flex justify-content-between align-items-center">
                      <p-sortIcon field="data"></p-sortIcon>
                      <p-columnFilter field="data" display="menu" matchMode="contains"></p-columnFilter>
                    </div>
                  </div>
                </th>
                <th pSortableColumn="operacaoDescricao">
                  <div class="flex justify-content-between align-items-center">
                    Operação
                    <div class="flex justify-content-between align-items-center">
                      <p-sortIcon field="operacaoDescricao"></p-sortIcon>
                      <p-columnFilter field="operacaoDescricao" display="menu" matchMode="contains"></p-columnFilter>
                    </div>
                  </div>
                </th>
                <th pSortableColumn="detalhe">
                  <div class="flex justify-content-between align-items-center">
                    Detalhe
                    <div class="flex justify-content-between align-items-center">
                      <p-sortIcon field="detalhe"></p-sortIcon>
                      <p-columnFilter field="detalhe" display="menu" matchMode="contains"></p-columnFilter>
                    </div>
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-projetoAud>
              <tr>
                <td>{{ projetoAud.grupo }}</td>
                <td>{{ projetoAud.usuario }}</td>
                <td>{{ projetoAud.data | date : "yyyy-MM-dd" }}</td>
                <td>{{ projetoAud.operacaoDescricao }}</td>
                <td>{{ projetoAud.detalhe }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-template>
  </p-dialog>
</div>
