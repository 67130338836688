<div class="login-container">
  <p-toast></p-toast>
  <div class="login-box">
    <div style="text-align: center">
      <img src="../../assets/images/Avalara.png"
      class="Header__Main__Logo__Primary"
      alt="Logo da sua aplicação">
    </div>
    <div style="text-align: center">
      <h2>PARTNER PROJECT PORTAL</h2>
    </div>
    <div class="p-fluid">
      <!-- Campo de Usuario -->
      <form [formGroup]="form" (ngSubmit)="handleSubmit()">
        <div class="p-field">
          <label for="nome"> Usuário: </label>
          <input
            class="custom-input"
            pInputText
            id="nome"
            placeholder="Usuário"
            type="text"
            formControlName="nome" />
        </div>
        <!-- Campo de Senha -->
        <div class="p-field">
          <label> Senha: </label>
          <p-password
            id="password"
            placeholder="Senha"
            formControlName="senha"
            [toggleMask]="true"
            class="custom-input" />
        </div>

        <!-- Botão Entrar -->
        <p-button type="submit" [disabled]="form.invalid || (form.touched && showLoader)" label="Entrar" class="custom-button"></p-button>
      </form>
      <BR>
      <i class="pi pi-question-circle" style="color:orange" pTooltip="Resetar senha" tooltipPosition="right" (click)="resetarSenha()">
        Nao consegue acessar a sua conta. Clique aqui.
      </i>
    </div>
  </div>
</div>
<footer>
  <div style="text-align: right">F:1.7.0</div>
  <div style="text-align: right">B:{{ backendVersion }}</div>
</footer>
