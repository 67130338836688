import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './login/login.module';
import { ManutencaoProjetosComponent } from './manutencao-projetos/manutencao-projetos.component';
import { OcorrenciaComponent } from './ocorrencia/ocorrencia.component';
import { ManutencaoGrupoComponent } from './manutencao-grupo/manutencao-grupo.component';
import { ManutencaoUsuarioComponent } from './manutencao-usuario/manutencao-usuario.component';
import { ExportacoesComponent } from './exportacoes/exportacoes.component';
import { ExecutivoComponent } from './relatorio-executivo/executivo.component';
import { AnaliticoComponent } from './relatorio-analitico/analitico.component';
import { RegrasAlertaComponent } from './regras-alerta/regras-alerta.component';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { ToolbarModule } from 'primeng/toolbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { CommonModule, DatePipe } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CardModule } from 'primeng/card';
import { InputMaskModule } from 'primeng/inputmask';
import { PhonePipe} from './shared/phone-pipe/phone.pipe';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { InterceptorModule } from './core/intercepor/auth.interceptor.module';
import { ChamadosCargaComponent } from './carga-chamados/carga.component';
import { ChamadoDadosComponent } from './dados-chamados/dados.component';
import { TruncatePipe } from './shared/truncate.pipe';
import { ChamadosExportacaoComponent } from './exportacao-chamados/exportacao-chamados.component';
import { ChamadosEscalationComponent } from './escalation-chamados/escalation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ResetSenhaComponent } from './reset-senha/reset-senha.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ResetSenhaComponent,
    ManutencaoProjetosComponent,
    OcorrenciaComponent,
    ManutencaoGrupoComponent,
    ManutencaoUsuarioComponent,
    ExportacoesComponent,
    ExecutivoComponent,
    AnaliticoComponent,
    RegrasAlertaComponent,
    ChamadosCargaComponent,
    ChamadoDadosComponent,
    ChamadosExportacaoComponent,
    ChamadosEscalationComponent,
    MenuBarComponent,
    TruncatePipe,
    PhonePipe
  ],
  imports: [
    InterceptorModule,
    LoginModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PasswordModule,
    InputTextModule,
    ButtonModule,
    MenubarModule,
    TableModule,
    ToolbarModule,
    OverlayPanelModule,
    MessagesModule,
    ToastModule,
    ConfirmPopupModule,
    DialogModule,
    HttpClientModule,
    FormsModule,
    CalendarModule,
    CommonModule,
    DropdownModule,
    PaginatorModule,
    TriStateCheckboxModule,
    ConfirmDialogModule,
    CardModule,
    InputMaskModule,
    InputTextareaModule,
    FileUploadModule,
    ChartModule,
    CheckboxModule
  ],
  providers: [
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
