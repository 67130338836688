import { ConfirmationService, MessageService } from 'primeng/api';
import { Component } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { interval, throwError } from 'rxjs';
import { Ocorrencia } from '../modelo/Ocorrencia';
import { OcorrenciaService } from './ocorrencia.service';
import { SharedDataService } from '../shared/shared-data/shared-data.service';
import { Projeto } from '../modelo/Projeto';
import { AuthService } from '../core/auth/service/auth.service';

@Component({
    selector: 'app-ocorrencia',
    templateUrl: './ocorrencia.component.html',
    styleUrls: ['./ocorrencia.component.css'],
    providers: [MessageService, ConfirmationService],
  })

  export class OcorrenciaComponent {
    ocorrencias: Ocorrencia[] = [];
    ocorrencia: Ocorrencia = new Ocorrencia();
    projeto?: Projeto;
    complemento?: String;

    dialog: boolean = false;
    visible: boolean = false;  
    submitted!: boolean;
    loading: boolean = false;
    lifeMessageToast: number = 7000;

    projetoId?: String | undefined | null;

    constructor(
        private service: OcorrenciaService,
        private messageService: MessageService,
        private sharedData: SharedDataService,
        private auth: AuthService    
      ) {}


    public fetchContent(): void {
      if (!this.auth.isAuthenticated())
        return;
      this.loading = true;
      this.service.getPorProjeto(this.projetoId).subscribe({
        next: (payload) => {
          this.ocorrencias = payload;
        },
        error: (error: HttpErrorResponse) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Ocorrencia',
            detail: 'Erro ao tentar buscar lista de ocorrencias!',
            life: this.lifeMessageToast,
          });
          console.error('Erro: ' + error);
        },
      });
      this.loading = false;
    }
  
      async ngOnInit() {
        await this.fetchContent();
        this.dialog = false;  

        this.projetoId = this.sharedData.getProjetoId();
        this.sharedData.cleanProjetoId();

        if (this.projetoId != undefined && this.projetoId != null)
          this.complemento = " - Projeto: " + this.projetoId;        
      }      

      addEdit(ocorrencia: Ocorrencia) {
        this.ocorrencia = { ...ocorrencia };
        this.dialog = true;
        this.visible = true;
        this.fetchContent();
      }

      notificarNovamente(ocorrencia: Ocorrencia) {
        this.ocorrencia = { ...ocorrencia };
        this.ocorrencia.notificado = false;
        this.salvarJustificativa();
        this.fetchContent();
      }

      hideDialog() {
        this.visible = false;
        this.submitted = false;
        this.fetchContent();
      }

      salvarJustificativa() {
        try {
            this.service.put(this.ocorrencia)
            .pipe(
                catchError(error => {
                    console.error('Erro ao salvar a justificativa:', error);
                    if (!error.error.error)
                      this.messageService.add({
                        severity: 'error',
                        summary: 'Ocorrencia',
                        detail: 'Erro ao salvar justificativa. ' + error.error,
                        life: this.lifeMessageToast,
                      });
                    return throwError(error);
                }))            
              .subscribe((response) => {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Ocorrencia',
                  detail: 'Justificativa gravada com sucesso',
                  life: this.lifeMessageToast,
                });
                this.visible = false;
                this.fetchContent();    
              });
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Ocorrencia',
            detail: 'Erro ao gravar justificativa!',
            life: this.lifeMessageToast,
          });
        }
      }      
    

}