<div class="reset-password-container">
  <p-toast></p-toast>
  <div class="reset-password-box">
    <div style="text-align: center">
      <img src="../../assets/images/Avalara.png" class="Header__Main__Logo__Primary" alt="Logo da sua aplicação">
    </div>
    <div style="text-align: center">
      <h2>PARTNER PROJECT PORTAL</h2>
      <h3>Redefinir Senha</h3>
      
    </div>
    <div style="text-align:justify">O processo de redefinir senha permite a recepção de uma nova senha de acesso. A nova senha sera enviada para o email cadastrado para o usuário.<BR><BR>
      Por razoes de segurança nao sera confirmado se o usuário indicado esta valido ou nao para o sistema. <BR><BR>
      Apos alguns instantes, caso o email nao seja recebido, verifique se o mesmo encontra-se no SPAM (Lixo Eletrônico). 
      Caso o email nao tenha sido recebido, tente novamente, persistindo contactar o administrador do sistema.
    </div><BR>

    <div class="p-fluid">
      <form (ngSubmit)="onSubmit()" #resetForm="ngForm">
        <div class="form-group">
          <label for="username">Usuário: </label>
          <input pInputText 
            placeholder="Usuário"
            id="username" 
            name="username" 
            [(ngModel)]="username" 
            required #usernameInput="ngModel"
            class="custom-input" />
        </div>
        <div *ngIf="usernameInput.invalid && usernameInput.touched" class="error">
          Nome de usuário é obrigatório
        </div>
        <p-button 
          type="submit" 
          class="custom-button" 
          [disabled]="resetForm.invalid == true">
          Enviar
        </p-button>
      </form>
    </div>
    <p *ngIf="message" class="message">{{ message }}</p>
  </div>
</div>