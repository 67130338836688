import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export class ResetSenhaService {

    apiUrl: string = `${environment.auth}/securiy/reset`;

    vazio: any[] = [];
  
    constructor(private http: HttpClient) { }
  
      httpOptions = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
          }
      }
  
    requisisarNovaSenha(usuario: string): Observable<any> {
        return this.http.post(`${this.apiUrl}`, usuario, { headers: this.httpOptions.headers });
      }
  
  }