import { Comentario } from './../modelo/Comentario';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IApiResponse } from '../modelo/PageProps';
import { Projeto } from '../modelo/Projeto';
import { Observable } from 'rxjs';
import { Upload } from '../modelo/Upload';

@Injectable({
  providedIn: 'root'
})
export class ProjetosService {

  apiUrl: string = `${environment.backendUrl}/projetos`;

  vazio: any[] = [];

  constructor(private http: HttpClient) { }

    httpOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
        }
    }

    httpOptionsMultiPart = {
      headers: {
          //'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
      }
  }

    getSolucoes(): Observable<string[]> {
      let searchParams = new HttpParams();
      return this.http.get<string[]>(`${this.apiUrl}/listaSolucoes`,
        { params: searchParams, headers: this.httpOptions.headers });
    }

    getOrigens(): Observable<string[]> {
      let searchParams = new HttpParams();
      return this.http.get<string[]>(`${this.apiUrl}/listaOrigens`,
        { params: searchParams, headers: this.httpOptions.headers });
    }

    getProjetos() {
      return this.getProjetosFiltro("", this.vazio);
    }

    private formatDate(date: Date): string {
      if (date == null)
        return "";
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    }

    getProjetosFiltro(
      tipoFiltro: string,
      filtroData: Date[]){

        console.info("Tipo Filtro: " + tipoFiltro);
        console.info("Datas: " + filtroData);

      let searchParams = new HttpParams()
        .set("tipoFiltro", tipoFiltro)
        .set("filtroData", filtroData.map(date =>
            this.formatDate(date)).join(";"));

      return this.http.get<IApiResponse>(`${this.apiUrl}`,
      { params: searchParams, headers: this.httpOptions.headers });
    }

    getHistorico(id: number, pageNumber: number, pageSize: number){
      let searchParams = new HttpParams();

      searchParams = searchParams.append(`page`, String(pageNumber));
      searchParams = searchParams.append(`size`, String(pageSize));

      return this.http.get<IApiResponse>(`${this.apiUrl}/aud/${id}`,
      { params: searchParams, headers: this.httpOptions.headers });
    }

    salvarProjeto(projeto: Projeto): Observable<any> {
      return this.http.post(`${this.apiUrl}`, projeto, { headers: this.httpOptions.headers });
    }

    editarProjeto( projeto: Projeto): Observable<any>{
      return this.http.put(`${this.apiUrl}/${projeto.id}`,
      projeto, { headers: this.httpOptions.headers });
    }

    deletarProjeto(projeto: Projeto): Observable<any> {
      return this.http.delete(`${this.apiUrl}/${projeto.id}`, this.httpOptions);
    }

    validaEncerramento(projeto: Projeto): Observable<any> {
      return this.http.put(`${this.apiUrl}/validaEncerramento/${projeto.id}`, this.httpOptions);
    }

    modificaPesquisaSatisfacao(projeto: Projeto): Observable<any> {
      return this.http.put(`${this.apiUrl}/pesquisaSatisfacao/${projeto.id}`, this.httpOptions);
    }

    listarComentarios(comentario: Comentario) : Observable<any>{
      let searchParams = new HttpParams();
      return this.http.get<IApiResponse>(`${this.apiUrl}/comentarios/${comentario.projeto?.id}`,
       { headers: this.httpOptions.headers });
    }

    inserirComentario(comentario: Comentario): Observable<any> {
      return this.http.post(`${this.apiUrl}/comentarios`, comentario,{ headers: this.httpOptions.headers });
    }

    uploadFile(file: File , upload: Upload){
      const formData = new FormData();
        formData.append('file', file);
      return this.http.post(`${this.apiUrl}/arquivos/${upload.projeto?.id}`,
      formData, { headers: this.httpOptionsMultiPart.headers ,  observe: 'response', responseType: 'text' });
    }

    listarArquivos(upload: Upload): Observable<any>{
      let searchParams = new HttpParams();
      return this.http.get<IApiResponse>(`${this.apiUrl}/arquivos/${upload.projeto?.id}`,
       { headers: this.httpOptions.headers });
    }

    downloadFile(upload: Upload): Observable<Blob>{
      const projetoId = upload.projeto?.id;
    const filename = upload.nome;
    const url = `${this.apiUrl}/arquivos/download/${projetoId}/${filename}`;
    return this.http.get(url, { headers: this.httpOptions.headers, responseType: 'blob'});
    }
}
