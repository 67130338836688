import { Upload } from './../modelo/Upload';
import { Status } from './../modelo/Status';
import { ProjetoAud } from './../modelo/ProjetoAud';
import { RegrasAlertaService } from './regras-alerta.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Projeto } from '../modelo/Projeto';
import { Regra } from '../modelo/Regra';
import { PageProps } from '../modelo/PageProps';
import { Grupo } from '../modelo/Grupo';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { Comentario } from '../modelo/Comentario';
import { catchError } from 'rxjs/operators';
import { interval, throwError } from 'rxjs';
import { RegraFrequencia } from '../modelo/RegraFrequenncia';
import { RegraCondicao } from '../modelo/RegraCondicao';
import { RegraTipoData } from '../modelo/RegraTipoData';
import { AuthService } from '../core/auth/service/auth.service';

type DataType = { label: string, value: string }

@Component({
  selector: 'app-regras-alerta',
  templateUrl: './regras-alerta.component.html',
  styleUrls: ['./regras-alerta.component.css'],
  providers: [MessageService, ConfirmationService, FileUploadModule],
})
export class RegrasAlertaComponent {
  @ViewChild(FileUpload) fileUpload!: FileUpload;

  data!: DataType;

  regra: Regra = new Regra();

  grupo: Grupo = {} = new Grupo();

  projetoAud: ProjetoAud = new ProjetoAud();
  projetosAud: any[] = [];

  status: any[] = [];
  grupos: any[] = [];
  solucoes: any[] = [];
  dtbase: any[] = [];
  condicoes: any[] = [];
  frequencias: any[] = [];
  origens: any[] = [];
  pesquisas: any[] = [];

  selectedProjetos: Projeto[] = [];
  regras: Regra[] = [];
  ProjetoPage: PageProps<Projeto> = {} as PageProps<Projeto>;

  selectedHistorico: ProjetoAud[] = [];
  historico: ProjetoAud[] = [];
  historicoPage: PageProps<ProjetoAud> = {} as PageProps<ProjetoAud>;

  dialog: boolean = false;
  visible: boolean = false;

  comentarioDialog: boolean = false;
  comentario: Comentario = new Comentario();
  selectedComentario: Comentario[] = [];
  comentarios: any[] = [];

  uploadDialog: boolean = false;
  upload: Upload = new Upload();
  selectedUpload: Upload [] = [];
  uploads: any[] = [];
  uploadFiles: any[] = [];
  processandoArquivo: boolean = false;

  totalRecords!: number;
  firstPage = true;
  totalPages = 0;
  pageNumber = 0;
  pageSize = 10;

  totalRecordsHistorico!: number;
  firstPageHistorico = true;
  totalPagesHistorico = 0;
  pageNumberHistorico = 0;
  pageSizeHistorico = 10;

  loading: boolean = false;
  submitted!: boolean;
  submittedGrupo!: boolean;
  submittedCliente!: boolean;
  submittedSolucao!: boolean;
  submittedDtBase!: boolean;
  submittedCondicao!: boolean;
  submittedStatus!: boolean;
  lifeMessageToast: number = 7000;

  constructor(
    private service: RegrasAlertaService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private auth: AuthService    
  ) {}

  async ngOnInit() {
    await this.fetchContent(this.pageNumber, this.pageSize);
    this.dialog = false;
    this.getSolucoes();
    
    for (const [values, keys] of Object.entries(Status)) 
      this.status.push({ chave: keys, valor: values });
    for (const [values, keys] of Object.entries(RegraFrequencia))
      this.frequencias.push({ chave: keys, valor: values });
    for (const [values, keys] of Object.entries(RegraCondicao))
      this.condicoes.push({ chave: keys, valor: values });
    for (const [values, keys] of Object.entries(RegraTipoData))
      this.dtbase.push({ chave: keys, valor: values });
    
    this.pesquisas.push({ valor: "N/A", chave: "Nao se aplica" });
    this.pesquisas.push({ valor: "Nao", chave: "Nao respondido" });

  }

  public fetchContent(page: number, rows: number): void {
    if (!this.auth.isAuthenticated())
      return;
    this.loading = true;
    this.service.getRegras(this.pageNumber, this.pageSize).subscribe({
      next: (payload) => {
        this.ProjetoPage = payload;
        this.regras = this.ProjetoPage.content;
        this.totalRecords = this.ProjetoPage.totalElements;
        this.totalPages = this.ProjetoPage.totalPages;
        this.pageSize = this.ProjetoPage.pageSize;
        this.pageNumber = this.ProjetoPage.currentPage;
        this.firstPage = this.ProjetoPage.firstPage;
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Regras',
          detail: 'Erro ao tentar buscar lista de regras!',
          life: this.lifeMessageToast,
        });
        console.error('Erro: ' + error);
      },
    });
    this.loading = false;
  }

  salvarRegra() {
    this.pageNumber = 0;
    this.pageSize = 5;
    this.submitted = true;
    this.submittedCliente = true;
    this.submittedGrupo = true;
    this.submittedSolucao = true;
    this.submittedDtBase = true;
    this.submittedCondicao = true;
    this.submittedStatus = true;
    if (this.regra.id) {
      try {
        this.service.editarRegra(this.regra)
        .pipe(
          catchError(error => {
              console.error('Erro ao salvar a regra:', error);
              if (!error.error.error)
                this.messageService.add({
                  severity: 'error',
                  summary: 'Regra',
                  detail: 'Erro ao salvar regra. ' + error.error,
                  life: this.lifeMessageToast,
                });
              return throwError(error);
          }))
        .subscribe((response) => {
          this.pageNumber = 0;
          this.pageSize = 5;
          //this.fetchContent(this.pageNumber, this.pageNumber);
          this.messageService.add({
            severity: 'success',
            summary: 'Regra',
            detail: 'Regra atualizada com sucesso!',
            life: this.lifeMessageToast,
          });
        });
        this.visible = false;
      } catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: 'Regra',
          detail: 'Erro ao tentar atualizar Regra!',
          life: this.lifeMessageToast,
        });
      }
    } else {
      try {
        console.log('salvar');

        if (this.regra.solucao != null && this.regra.status != null) {
          this.service.salvarRegra(this.regra)
          .pipe(
            catchError(error => {
                console.error('Erro ao salvar a regra:', error);
                this.messageService.add({
                  severity: 'error',
                  summary: 'Regra',
                  detail: 'Erro ao salvar regra! ' + error,
                  life: this.lifeMessageToast,
                });
                return throwError(error);
            })
          )
          .subscribe((response) => {
            this.pageNumber = 0;
            this.pageSize = 5;
            this.messageService.add({
              severity: 'success',
              summary: 'Regra',
              detail: 'Regra salvo com sucesso!',
              life: this.lifeMessageToast,
            });
          });
          this.visible = false;
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Regra',
            detail: 'Campos obrigatórios não preenchidos!',
            life: this.lifeMessageToast,
          });
          return;
        }
      } catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: 'Regra',
          detail: 'Erro ao tentar salvar Regra!',
          life: this.lifeMessageToast,
        });
      }
      this.fetchContent(this.pageNumber, this.pageSize);
    }
    
  }

  deletarRegra(dados: any) {
    this.pageNumber = 0;
    this.pageSize = 5;
    this.confirmationService.confirm({
      header: 'Confirmação',
      message: 'Tem certeza que deseja deletar?',
      icon: 'pi pi-exclamation-triangle',
      blockScroll: true,
      dismissableMask: true,
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptVisible: true,
      closeOnEscape: true,
      accept: () => {
        this.service
          .deletarRegra(dados)
          .toPromise()
          .then((response) => {
            this.fetchContent(this.pageNumber, this.pageSize);
          });
        //});
        this.messageService.add({
          severity: 'success',
          summary: 'Regra',
          detail: 'Regra deletada com sucesso!',
          life: this.lifeMessageToast,
        });
        this.fetchContent(this.pageNumber, this.pageSize);
      },
      reject: () => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Regra',
          detail: 'Operação cancelada!',
          life: this.lifeMessageToast,
        });
      },
    });
    this.fetchContent(this.pageNumber, this.pageSize);
  }

  getSolucoes() {
    this.solucoes = this.solucoes.concat("*** TODAS ***");
    this.service.getSolucoes().subscribe(
      (response) => {
        this.solucoes = this.solucoes.concat(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  add() {
    this.regra = new Regra();
    this.visible = true;
  }

  addEdit(regra: Regra) {
    this.pageNumber = 0;
    this.pageSize = 5;
    this.regra = { ...regra };
    this.dialog = true;
    this.visible = true;
    this.fetchContent(this.pageNumber, this.pageSize);
  }

  solucaoSelecionado(e: any) {
    this.regra.solucao = e.value;
  }

  dataBaseSelecionado(e: any) {
    this.regra.dtbase = e.value;
  }

  dataAuxiliarSelecionado(e:any) {
    this.regra.dtauxiliar = e.value;
  }

  condicaoSelecionado(e: any) {
    this.regra.condicao = e.value;
  }

  hideDialog() {
    this.visible = false;
    this.submitted = false;
    this.pageNumber = 0;
    this.pageSize = 5;
    this.fetchContent(this.pageNumber, this.pageSize);
  }

  paginateRegra(e: any) {
    let pageIndex = e.first / e.rows;
    this.pageNumber = pageIndex;
    this.pageSize = e.rows;
    this.fetchContent(this.pageNumber, this.pageSize);
  }

}
