import { catchError } from 'rxjs/operators';
import { Component } from "@angular/core";
import { ConfirmationService, MessageService } from 'primeng/api';
import { ChamadoDadosService } from './dados.service';
import { AuthService } from 'src/app/core/auth/service/auth.service';
import { PageProps } from '../modelo/PageProps';
import { HttpErrorResponse } from '@angular/common/http';
import { interval, throwError } from 'rxjs';
import { EscalationService } from '../escalation-chamados/escalation.service';


@Component({
    selector: 'app-carga',
    templateUrl: './dados.component.html',
    styleUrls: ['./dados.component.css'],
    providers: [MessageService, ConfirmationService]
})

export class ChamadoDadosComponent {
    dados: any [] = [];
    dadoChamado: any = undefined;
    DadosPage: PageProps<any> = {} as PageProps<any>;
    dadosDialog: boolean = false;
    lifeMessageToast: number = 7000;
    loading: boolean = false;
    usuarioLogado: string | null;
    visible: boolean = false;

    optionsImpacto = [
        {label: "Alto", value: "Alto"},
        {label: "Médio", value: "Médio"},
        {label: "Baixo", value: "Baixo"}];

    optionsClasseRCA = [
        {label: "Ação pontual", value: "Ação pontual"},
        {label: "Ambiente cliente", value: "Ambiente cliente"},
        {label: "Comunicação", value: "Comunicação"},
        {label: "Processo", value: "Processo"},
        {label: "Produto", value: "Produto"},
        {label: "Qualidade", value: "Qualidade"},
        {label: "Recursos", value: "Recursos"},
        {label: "Suporte", value: "Suporte"}];
    
    constructor(
        private auth: AuthService,
        private service: ChamadoDadosService,
        private escalation: EscalationService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService
        ) {
            this.usuarioLogado = this.auth.obterUsuarioLogado();
        }
        
        async ngOnInit() {
            await this.fetchContent();          
        }            
        
        public fetchContent(): void {
            if (!this.auth.isAuthenticated())
            return;
            this.loading = true;
            this.service.getDados().subscribe({
                next: (payload) => {
                    this.DadosPage = payload;
                    this.dados = this.DadosPage.content;
                    console.info(this.dados);
                },
                error: (error: HttpErrorResponse) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Projetos',
                        detail: 'Erro ao tentar buscar lista de processamentos',
                        life: this.lifeMessageToast,
                    });
                },
            });
            this.loading = false;
            
        }
        
        ExcluirChamado(chamado: any) {
            this.confirmationService.confirm({
                header: 'Confirmação',
                message: 'Tem certeza que deseja deletar?',
                icon: 'pi pi-exclamation-triangle',
                blockScroll: true,
                dismissableMask: true,
                acceptLabel: 'Sim',
                rejectLabel: 'Não',
                acceptVisible: true,
                closeOnEscape: true,
                accept: () => {
                    this.service.deletarChamado(chamado.id)
                    .toPromise()
                    .then((response) => {
                        this.fetchContent();
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Chamado',
                            detail: 'Registro deletado com sucesso!',
                            life: this.lifeMessageToast,
                        });    
                    })
                    .catch((error) => {
                        this.messageService.add({
                          severity: 'error',
                          summary: 'Erro ao deletar chamado',
                          detail: error.error,
                          life: this.lifeMessageToast,
                        });
                    });      
                }
            });    
        }

        EscalationChamado(chamado: any) {
            this.dadoChamado = chamado;
            this.visible = true;
        }

        inserirEscalation() {
            if (this.dadoChamado.impacto != null && 
                this.dadoChamado.causaRaiz != null)
                this.escalation.salvar(this.dadoChamado)
                .pipe(
                    catchError(error => {
                        console.error('Erro ao salvar o projeto:', error);
                        this.messageService.add({
                        severity: 'error',
                        summary: 'Escalation',
                        detail: 'Erro ao salvar escalation! ' + error.error,
                        life: this.lifeMessageToast,
                        });
                        return throwError(error);
                    })
                )
                .subscribe((response) => {
                    this.messageService.add({
                    severity: 'success',
                    summary: 'Escalation',
                    detail: 'Escalation gravada com sucesso!',
                    life: this.lifeMessageToast,
                    });
                    this.hideDialog();
                });
            else
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Escalation',
                    detail: 'Campos obrigatórios não preenchidos!',
                    life: this.lifeMessageToast});
        }

        hideDialog() {
            this.visible = false;
        }

    }
    