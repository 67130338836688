import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../core/auth/service/auth.service';
import { Router } from '@angular/router';
import { MenuService } from './menu-bar.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.css']
})
export class MenuBarComponent implements OnInit{
  items: MenuItem[]= [];
  submenus: MenuItem[] = [];
  usuarioLogado: string | null;
  grupoLogado: string | null;
  acessoRestrito: boolean;
  exibeRegras: boolean = true;

  constructor(
  private auth: AuthService,
  private router: Router,
  private service: MenuService,
  private cdr: ChangeDetectorRef) {
    this.usuarioLogado = this.auth.obterUsuarioLogado();
    this.grupoLogado = this.auth.obterGrupoLogado();
    this.acessoRestrito = this.auth.obterAcessoLogado();
  }

  async ngOnInit() {

    this.submenus.push({
      label: "Dashboard",
      icon: "pi pi-chart-line",
      url: "dashboard"
    });

    this.submenus.push({
      label: "Configurações",
      icon: 'pi pi-cog',
      items: [{
        label: "Grupos",
        icon: 'pi pi-users',
        url: 'manutencao-grupo',
        visible: !this.acessoRestrito},
      { label: "Usuários",
        icon: 'pi pi-user',
        url: 'manutencao-usuario'},
      { label: "Regras de Alerta",
        icon: "pi pi-exclamation-triangle",
        url: "regras-alerta",
        visible: !this.acessoRestrito
      }]
    });

    this.submenus.push({
      label: "Projetos",
      icon: "pi pi-desktop",
      items: [{
        label: "Manutenção",
        icon: "pi pi-desktop",
        url: "manutencao-projetos"},
      { label: "Ocorrências",
        icon: "pi pi-exclamation-circle",
        url: "ocorrencia"},
      { label: "Exportações",
        icon: 'pi pi-file-excel',
        url: 'exportacoes'},
      { label: "Relatório Executivo",
        icon: 'pi pi-file-pdf',
        url: 'executivo'},
      { label: "Relatório Analítico",
        icon: 'pi pi-file-pdf',
        url: 'analitico'}
    ]
    });

    this.submenus.push({
      label: "Chamados",
      icon: "pi pi-table",
      items: [{
        label: "Carga",
        icon: "pi pi-download",
        url: "chamados-carga"
      },
      {
        label: "Consulta",
        icon: "pi pi-list",
        url: "chamados-consulta"
      },
/*      {
        label: "Escalation",
        icon: "pi pi-ban",
        url: "chamados-escalation"
      },*/
      {
        label: "Exportações",
        icon: "pi pi-upload",
        url: "chamados-exportacoes"
      }]
    })

    this.items = [{
        label: "",
        icon: 'pi pi-bars',
        items: this.submenus
      }]

  }

  logout() {
    this.auth.destroySession();
    this.router.navigateByUrl('login');
  }
}
