import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ManutencaoGrupoComponent } from './manutencao-grupo/manutencao-grupo.component';
import { ManutencaoProjetosComponent } from './manutencao-projetos/manutencao-projetos.component';
import { ManutencaoUsuarioComponent } from './manutencao-usuario/manutencao-usuario.component';
import { ExportacoesComponent } from './exportacoes/exportacoes.component';
import { ExecutivoComponent } from './relatorio-executivo/executivo.component';
import { AuthGuard } from './core/auth/guard/auth.guard';
import { AnaliticoComponent } from './relatorio-analitico/analitico.component';
import { RegrasAlertaComponent } from './regras-alerta/regras-alerta.component';
import { OcorrenciaComponent } from './ocorrencia/ocorrencia.component';
import { ChamadosCargaComponent } from './carga-chamados/carga.component';
import { ChamadoDadosComponent } from './dados-chamados/dados.component';
import { ChamadosExportacaoComponent } from './exportacao-chamados/exportacao-chamados.component';
import { ChamadosEscalationComponent } from './escalation-chamados/escalation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResetSenhaComponent } from './reset-senha/reset-senha.component';

const routes: Routes = [
  {
    path: '',
    component: ManutencaoProjetosComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'reset',
    component: ResetSenhaComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'manutencao-grupo',
    component: ManutencaoGrupoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'manutencao-projetos', 
    component: ManutencaoProjetosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ocorrencia', 
    component: OcorrenciaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'manutencao-usuario', 
    component: ManutencaoUsuarioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'exportacoes', 
    component: ExportacoesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'executivo', 
    component: ExecutivoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'analitico', 
    component: AnaliticoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'regras-alerta',
    component: RegrasAlertaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'chamados-carga',
    component: ChamadosCargaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'chamados-consulta',
    component: ChamadoDadosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'chamados-exportacoes',
    component: ChamadosExportacaoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'chamados-escalation',
    component: ChamadosEscalationComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
