import { AuthService } from 'src/app/core/auth/service/auth.service';
import { catchError } from 'rxjs/operators';
import { Component } from "@angular/core";
import { ConfirmationService } from 'primeng/api';
import { EscalationService } from './escalation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { interval } from 'rxjs';
import { MessageService } from "primeng/api";
import { PageProps } from '../modelo/PageProps';
import { throwError } from 'rxjs';
import { ProjetosService } from '../manutencao-projetos/projetos.service';

@Component({
    selector: 'app-escalation',
    templateUrl: './escalation.component.html',
    styleUrls: ['./escalation.component.css'],
    providers: [MessageService, ConfirmationService]
})

export class ChamadosEscalationComponent {

    dadosEscalation: any = {};
    dados: any [] = [];
    DadosPage: PageProps<any> = {} as PageProps<any>;
    lifeMessageToast: number = 7000;
    loading: boolean = false;
    solucoes: any[] = [];
    usuarioLogado: string | null;
    visible: boolean = false;

    optionsImpacto = [
        {label: "Alto", value: "Alto"},
        {label: "Médio", value: "Médio"},
        {label: "Baixo", value: "Baixo"}];

    optionsClasseRCA = [
        {label: "Ação pontual", value: "Ação pontual"},
        {label: "Ambiente cliente", value: "Ambiente cliente"},
        {label: "Comunicação", value: "Comunicação"},
        {label: "Processo", value: "Processo"},
        {label: "Produto", value: "Produto"},
        {label: "Qualidade", value: "Qualidade"},
        {label: "Recursos", value: "Recursos"},
        {label: "Suporte", value: "Suporte"}];

    constructor(
        private auth: AuthService,
        private service: EscalationService,
        private messageService: MessageService,
        private projetoService: ProjetosService
        ) {
            this.usuarioLogado = this.auth.obterUsuarioLogado();
    }

    async ngOnInit() {
        await this.fetchContent();
        this.getSolucoes();
    }

    public fetchContent(): void {
        if (!this.auth.isAuthenticated())
        return;
        this.loading = true;
        this.service.getDados().subscribe({
            next: (payload) => {
                this.DadosPage = payload;
                this.dados = this.DadosPage.content;
                console.info(this.dados);
            },
            error: (error: HttpErrorResponse) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Projetos',
                    detail: 'Erro ao tentar buscar lista de processamentos',
                    life: this.lifeMessageToast,
                });
            },
        });
        this.loading = false;
    }


    Excluir(escalation: any) {
        this.service.deletar(escalation)
        .toPromise()
        .then((response) => {
            this.fetchContent();
            this.messageService.add({
                severity: 'success',
                summary: 'Escalation',
                detail: 'Registro deletado com sucesso!',
                life: this.lifeMessageToast,
            });
        })
        .catch((error) => {
            this.messageService.add({
                severity: 'error',
                summary: 'Erro ao deletar escalation',
                detail: error.error,
                life: this.lifeMessageToast,
            });
        });
    }

    add() {
        this.visible = true;
    }

    hideDialog() {
        this.visible = false;
    }

    inserirEscalation() {
        if (this.dadosEscalation.cliente != null &&
            this.dadosEscalation.produto != null &&
            this.dadosEscalation.problemaRelatado != null &&
            this.dadosEscalation.impacto != null &&
            this.dadosEscalation.classeRCA != null &&
            this.dadosEscalation.causaRaiz != null)
            this.service.salvar(this.dadosEscalation)
            .pipe(
                catchError(error => {
                    console.error('Erro ao salvar o projeto:', error);
                    this.messageService.add({
                    severity: 'error',
                    summary: 'Escalation',
                    detail: 'Erro ao salvar escalation! ' + error.error,
                    life: this.lifeMessageToast,
                    });
                    return throwError(error);
                })
            )
            .subscribe((response) => {
                this.messageService.add({
                severity: 'success',
                summary: 'Escalation',
                detail: 'Escalation gravada com sucesso!',
                life: this.lifeMessageToast,
                });
                this.fetchContent();
                this.hideDialog();
            });
        else
            this.messageService.add({
                severity: 'warn',
                summary: 'Escalation',
                detail: 'Campos obrigatórios não preenchidos!',
                life: this.lifeMessageToast});
    }

    getSolucoes() {
        this.projetoService.getSolucoes().subscribe(
          (response) => {
            this.solucoes = this.solucoes.concat(response);
          },
          (error) => {
            console.log(error);
          }
        );
      }

}
